import React from 'react';
import ReactDOM from 'react-dom';
import { Map, ZoomControl, Marker, NavigationControl } from 'react-bmapgl';
import './reset.css'


class App extends React.Component {
  render() {
    return (
      <Map style={{height: '100vh'}} center={{ lng: 116.402544, lat: 39.928216 }} zoom="11">
        <Marker position={{ lng: 116.402544, lat: 39.928216 }} onClick={() => { window.location.href = "http://indoorinfo.cn/buceaquanjing21" }}/>
        <NavigationControl />
        <ZoomControl />
      </Map>
    )
  }
}

ReactDOM.render(<App />, document.getElementById('container'));